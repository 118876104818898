import Vue from "vue";
import VueRouter from "vue-router";
import api from "../api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "main" */ "../views/Home.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/:year(\\d+)",
    name: "home",
    component: () => import(/* webpackChunkName: "main" */ "../views/Home.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/:month(\\d+-\\d+)",
    name: "home",
    component: () => import(/* webpackChunkName: "main" */ "../views/Home.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      public: true,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/upload",
    name: "upload",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/Upload.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/duplicates",
    name: "duplicates",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Duplicates.vue"),
    meta: {
      public: false,
      admin: true,
    },
  },
  {
    path: "/pending",
    name: "pending",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Pending.vue"),
    meta: {
      public: false,
      admin: true,
    },
  },
  {
    path: "/identify",
    name: "identify",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Identify.vue"),
    meta: {
      public: false,
      admin: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const promise = Promise.resolve();
  promise.then(() => {
    const isPublic = to.matched.every((record) => record.meta.public);
    const isAdmin = to.matched.some((record) => record.meta.admin);
    const isRedirect = to.matched.every(
      (record) => record.meta.redirectIfLoggedIn
    );
    if (!isPublic || isRedirect) {
      api
        .getCurrentUser()
        .then((user) => {
          if (!user && !isPublic) {
            next({
              name: "login",
              params: { redirectTo: to },
            });
          } else if (user && isRedirect) {
            next("/");
          } else if (isAdmin && user && user.role !== "admin") {
            next("/");
          } else {
            next();
          }
        })
        .catch(() => {
          if (!isPublic && to.name !== "login") {
            next({
              name: "login",
              params: { redirectTo: to },
            });
          } else {
            next();
          }
        });
    } else {
      next();
    }
  });
});

export default router;
