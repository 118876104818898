import dayjs from "dayjs";

const date = (value, format) => {
  if (!value) {
    return "";
  }
  if (!format) {
    format = "DD/MM/YYYY HH:mm";
  }
  return dayjs(value).format(format);
};

export default date;
