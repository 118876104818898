<template>
  <div class="navbar">
    <div class="navbar-content" @click.stop>
      <ul>
        <li>
          <router-link to="/">Toutes les photos/vidéos</router-link>
        </li>
        <li style="margin-top: 20px">
          <router-link to="/upload">Ajouter des photos</router-link>
        </li>
        <template v-if="currentUser && currentUser.role === 'admin'">
          <li style="margin-top: 20px">
            <router-link to="/identify">Identify</router-link>
          </li>
          <li>
            <router-link to="/pending">Pending</router-link>
          </li>
          <li>
            <router-link to="/duplicates">Duplicates</router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>
