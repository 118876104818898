<template>
  <div class="overlay">
    <transition name="fade-faster" mode="out-in">
      <video
        v-if="media.type === 'video'"
        :key="media._id"
        :src="mediaUrl"
        class="overlay-video"
        :class="'rotate-' + rotate"
        :style="size"
        autoplay
        controls
        @click.stop
      ></video>
      <img
        v-else
        :key="media._id"
        :src="mediaUrl"
        class="overlay-image"
        :class="'rotate-' + rotate"
        :style="size"
        @click.stop
      />
    </transition>
    <Icon name="x" size="40" class="overlay-close" @click="close()"></Icon>
  </div>
</template>

<script>
import { throttle } from "throttle-debounce";

import Icon from "./Icon";

export default {
  name: "FullScreenMedia",
  components: {
    Icon,
  },
  props: ["mediaObj", "medias", "index"],
  data() {
    return {
      media: this.medias[this.index],
      currentIndex: this.index,
      rotate: 0,
      touchStartPos: null,
    };
  },
  created() {
    this._rotateLeft = throttle(250, this.rotateLeft);
    this._rotateRight = throttle(250, this.rotateRight);
  },
  mounted() {
    window.addEventListener("keydown", this.handleKey, true);
    this.$el.addEventListener("touchstart", this.onTouchStart, false);
    this.$el.addEventListener("touchend", this.onTouchEnd, false);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKey, true);
  },
  computed: {
    mediaUrl() {
      return (
        this.env.apiHost +
        "/medias" +
        (this.media.type === "image" ? this.media.path : this.media.lowPath)
      );
    },
    size() {
      const size = {};
      const rotated = this.rotate % 180 !== 0;
      const oriented =
        this.media.orientation === 90 || this.media.orientation === 270;
      const winW = window["inner" + (rotated ? "Height" : "Width")];
      const winH = window["inner" + (rotated ? "Width" : "Height")];
      const winR = winW / winH;
      const mediaSize = {
        w: oriented ? this.media.size.h : this.media.size.w,
        h: oriented ? this.media.size.w : this.media.size.h,
      };
      const mediaR = mediaSize.w / mediaSize.h;
      if (mediaR < winR) {
        size.height = winH + "px";
        size.width = mediaR * winH + "px";
      } else {
        size.width = winW + "px";
        size.height = winW / mediaR + "px";
      }

      return size;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    rotateLeft() {
      this.rotate = (this.rotate + 90) % 360;
    },
    rotateRight() {
      this.rotate = (this.rotate + 270) % 360;
    },
    nextMedia() {
      if (this.currentIndex + 1 < this.medias.length) {
        this.currentIndex++;
        this.media = this.medias[this.currentIndex];
      }
    },
    prevMedia() {
      if (this.currentIndex - 1 >= 0) {
        this.currentIndex--;
        this.media = this.medias[this.currentIndex];
      }
    },
    handleKey(e) {
      let events = {
        27: "close",
        37: "prev",
        38: "rotateLeft",
        39: "next",
        40: "rotateRight",
      };
      let event = events[e.keyCode];
      if (event) {
        e.preventDefault();
      }
      if (event === "next") {
        this.nextMedia();
      } else if (event === "prev") {
        this.prevMedia();
      } else if (event === "rotateRight") {
        this._rotateRight();
      } else if (event === "rotateLeft") {
        this._rotateLeft();
      } else if (event === "close") {
        this.close();
      }
    },
    onTouchStart(e) {
      if (e.changedTouches && e.changedTouches.length > 1) {
        this.touchStartPos = null;
        return;
      }
      this.touchStartPos = {
        x: e.changedTouches[0].screenX,
        y: e.changedTouches[0].screenY,
      };
    },
    onTouchEnd(e) {
      if (this.touchStartPos) {
        const touchEndPos = {
          x: e.changedTouches[0].screenX,
          y: e.changedTouches[0].screenY,
        };
        const absX = Math.abs(touchEndPos.x - this.touchStartPos.x);
        const absY = Math.abs(touchEndPos.y - this.touchStartPos.y);
        if (absX > absY && absX > 30) {
          if (this.touchStartPos.x > touchEndPos.x) {
            this.nextMedia();
          } else {
            this.prevMedia();
          }
        } else if (absY > absX && absY > 30) {
          if (touchEndPos.y > this.touchStartPos.y) {
            this.rotateRight();
          } else {
            this.rotateLeft();
          }
        }
      }
      this.touchStartPos = null;
    },
  },
  watch: {
    media() {
      requestAnimationFrame(() => {
        this.rotate = 0;
      });
    },
  },
};
</script>
