import EventEmitter from "eventemitter3";
import Api from "./Api";

const Storage = typeof window === "undefined" ? null : window.localStorage;

class AuthApi extends Api {
  constructor(opts) {
    super(opts);
    this.currentToken = null;
    this.refreshTokenUrl = opts.refreshTokenUrl || "/auth/local/token";
    this.cookieDomain = opts.cookieDomain || null;
    this.currentUser = null;
    this.currentUserPromise = Promise.resolve(null);
    this.emitter = new EventEmitter();
    if (Storage && opts.localStorage !== false) {
      this._storage = Storage;
    } else {
      const storage = {};
      this._storage = {
        getItem: (key) => {
          return storage[key] || null;
        },
        setItem: (key, value) => {
          storage[key] = value;
        },
      };
    }
  }

  beforeRequest(url, options) {
    let token = this.getToken();
    if (token) {
      options.headers = options.headers || {};
      options.headers.Authorization = "Bearer " + token;
    }
    return Promise.resolve(options);
  }

  handleResponse(response, url, options, tokenRefreshed) {
    if (response.status === 401 && !tokenRefreshed) {
      return this.refreshToken()
        .catch(() => {
          return super.handleResponse(response, url, options);
        })
        .then(() => {
          return this.fetch(url, options, true);
        });
    } else {
      return super.handleResponse(response, url, options);
    }
  }

  getStorage() {
    return this._storage;
  }

  getToken() {
    let token = this.getStorage().getItem("token");
    if (token) {
      try {
        token = JSON.parse(token);
      } catch (e) {
        // do nothing if json parse failed
      }
      if (this.currentToken && token !== this.currentToken) {
        window && window.location.reload();
        return this.currentToken;
      }
    }
    return token;
  }

  setTokens(data) {
    this.getStorage().setItem("token", JSON.stringify(data.token));
    let cookie = `token=${data.token}; path=/`;
    if (this.cookieDomain) {
      cookie += `; domain=${this.cookieDomain}`;
    }
    document.cookie = cookie;
    this.getStorage().setItem(
      "refreshToken",
      JSON.stringify(data.refreshToken)
    );
    this.currentToken = data.token;
  }

  clearTokens() {
    this.getStorage().removeItem("token");
    this.getStorage().removeItem("refreshToken");
    this.currentToken = null;
  }

  refreshToken() {
    let refreshToken = this.getStorage().getItem("refreshToken");

    if (!refreshToken) {
      return Promise.reject(new Error("ERROR_NO_REFRESH_TOKEN"));
    } else {
      try {
        refreshToken = JSON.parse(refreshToken);
      } catch (e) {
        // do nothing if json parse failed
      }
    }
    return fetch(this.url + this.refreshTokenUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ refreshToken: refreshToken }),
    })
      .then((data) => {
        return data.text();
      })
      .then((responseText) => {
        let data;
        try {
          data = JSON.parse(responseText);
        } catch (e) {
          throw new Error(responseText);
        }
        return data;
      })
      .then((data) => {
        this.setTokens(data);
        return true;
      });
  }

  getCurrentUser() {
    return this.currentUser
      ? Promise.resolve(this.currentUser)
      : this.currentUserPromise;
  }

  setCurrentUser(user) {
    if (user) {
      this.currentUser = user;
      this.currentUserPromise = Promise.resolve(user);
    } else {
      this.currentUser = null;
      this.currentUserPromise = Promise.resolve(null);
    }
    this.emitter.emit("currentUserPromise", this.currentUserPromise);
    this.emitter.emit("currentUser", user);
  }

  setCurrentUserPromise(promise) {
    this.currentUserPromise = promise.then((user) => {
      this.currentUser = user;
      this.emitter.emit("currentUser", user);
      return user;
    });
    this.emitter.emit("currentUserPromise", this.currentUserPromise);
  }

  on(event, callback) {
    this.emitter.on(event, callback);
  }

  off(event, callback) {
    this.emitter.off(event, callback);
  }
}

export default AuthApi;
