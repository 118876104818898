import Vue from "vue";
import App from "./App.vue";
import api from "./api";
import env from "./env";
import router from "./router";
import dateFilter from "./filters/date";

Vue.config.productionTip = false;

Vue.prototype.api = api;
Vue.prototype.env = env;

Vue.filter("date", dateFilter);

let mediaMonthsPromise = null;
Vue.prototype.getMediaMonths = () => {
  if (!mediaMonthsPromise) {
    mediaMonthsPromise = api.Media.getMonths();
  }
  return mediaMonthsPromise;
};

const userObservable = Vue.observable({
  user: null,
});
let userId = null;

api.on("currentUser", (user) => {
  userObservable.user = user;
  if (user && user._id !== userId) {
    userId = user._id;
  }
});

Vue.mixin({
  computed: {
    currentUser: () => userObservable.user,
  },
});

if (api.getToken()) {
  api.setCurrentUserPromise(api.User.me());
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
