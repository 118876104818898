const Media = {
  url: "/medias",
  routes: {
    create: {
      method: "POST",
      route: "",
      multipart: true,
    },
    getMonths: {
      method: "GET",
      route: "/months",
    },
    getDuplicates: {
      method: "GET",
      route: "/duplicates",
    },
    identify: {
      method: "POST",
      route: "/identify",
    },
  },
};

export default Media;
