<template>
  <div id="app" :class="{ loaded: loaded || isPublic }" @click="closeNavbar()">
    <router-view></router-view>
    <transition name="fade-fast" transition-delay="500">
      <div
        v-if="!isPublic && isNavbarOpen"
        style="position: absolute; inset: 0; background: rgba(0, 0, 0, 0.75)"
        @click="closeNavbar"
      ></div>
    </transition>
    <transition>
      <Navbar v-if="!isPublic && isNavbarOpen" />
    </transition>

    <div class="navbar-toggle" @click.stop="toggleNavbar()" v-if="!isPublic">
      <transition name="fade-fast" mode="out-in">
        <Icon name="x" size="16" v-if="isNavbarOpen" key="close" />
        <Icon name="menu" size="16" v-else key="open" />
      </transition>
    </div>
    <FullScreenMedia
      v-if="!isPublic && openMediaData"
      :index="openMediaData.index"
      :medias="openMediaData.medias"
      @close="closeMedia"
    />
  </div>
</template>

<script>
import FullScreenMedia from "./components/FullScreenMedia.vue";
import Icon from "./components/Icon.vue";
import Navbar from "./components/Navbar.vue";

export default {
  name: "app",
  components: {
    FullScreenMedia,
    Icon,
    Navbar,
  },
  data() {
    return {
      openMediaData: null,
      isNavbarOpen: false,
    };
  },
  computed: {
    loaded() {
      return !!this.currentUser;
    },
    isPublic() {
      return this.$route.meta.public;
    },
  },
  created() {
    this.$root.$on("open-media", this.openMedia);
  },
  destroyed() {
    this.$root.$off("open-media", this.openMedia);
  },
  methods: {
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    closeNavbar() {
      this.isNavbarOpen = false;
    },
    openMedia(data) {
      this.openMediaData = data;
    },
    closeMedia() {
      this.openMediaData = null;
    },
  },
  watch: {
    $route() {
      if (this.isNavbarOpen) {
        this.isNavbarOpen = false;
      }
    },
  },
};
</script>

<style lang="scss">
.view {
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
<style lang="scss" src="../style/app.scss"></style>
