const defaultQueries = {
  query: {
    method: "GET",
    route: "",
  },
  get: {
    method: "GET",
    route: "/:_id",
    arguments: ["_id"],
  },
  create: {
    method: "POST",
    route: "",
  },
  update: {
    method: "PUT",
    route: "/:_id",
  },
  destroy: {
    method: "DELETE",
    route: "/:_id",
    arguments: ["_id"],
  },
};

class Api {
  constructor(opts) {
    this.url = opts.url;
    this.customFetch = typeof opts.fetch === "function" ? opts.fetch : null;
  }

  beforeRequest(url, options) {
    return Promise.resolve(options);
  }

  handleResponse(response, url, options) {
    const type = options.responseType || "json";
    let promise;
    if (response.type === false) {
      promise = Promise.reject(new Error());
    } else if (type === "json") {
      promise = response.text();
    } else {
      promise = response[type]();
    }
    return promise.then((result) => {
      if (type === "json") {
        try {
          result = JSON.parse(result);
        } catch (e) {
          // do nothing if json parse failed
        }
      }
      if (response.status >= 400) {
        const err = new Error(result);
        err.code = response.status;
        throw err;
      }
      return result;
    });
  }

  handleError(err) {
    return Promise.reject(err);
  }

  fetch(routeUrl, options, ...args) {
    let url = this.url + routeUrl;
    return this.beforeRequest(url, options, ...args)
      .then((opts) => {
        if (opts && opts.url && opts.options) {
          return (this.customFetch || fetch)(opts.url, opts.options);
        } else {
          return (this.customFetch || fetch)(url, opts);
        }
      })
      .catch((err) => {
        return this.handleError(err, routeUrl, options, ...args);
      })
      .then((res) => {
        return this.handleResponse(res, routeUrl, options, ...args);
      });
  }

  resource(name, args) {
    const resource = {};
    let url = args.url;
    let routes = args.routes || {};
    let data = args.data || {};
    let methods = args.methods || {};
    resource.endPoint = url;
    resource.routeOptions = Object.assign(
      {},
      args.defaultRoutes !== false ? defaultQueries : {},
      routes || {}
    );
    resource.data = data || {};
    resource.routes = {};
    Object.entries(resource.routeOptions).forEach((entry) => {
      const routeName = entry[0];
      const route = entry[1];
      if (!routeName || !route) {
        return;
      }
      resource[routeName] = resource.routes[routeName] = (...fnArguments) => {
        let routeUrl = resource.endPoint;
        let isPostMethod =
          ["POST", "PUT", "PATCH"].indexOf(route.method) !== -1;
        let routeArgs = {};
        let queryData;
        let postData;
        let lastArg = route.arguments ? route.arguments.length : 0;
        let options = {
          headers: {},
        };
        (route.arguments || []).forEach((arg, index) => {
          routeArgs[arg] = fnArguments[index];
        });
        queryData = fnArguments[lastArg];
        postData = fnArguments[lastArg + 1];

        if (isPostMethod) {
          if (postData === undefined) {
            postData = route.multipart
              ? queryData
              : Object.assign({}, queryData);
            queryData = null;
          } else {
            postData = route.multipart ? postData : Object.assign({}, postData);
          }
        }
        if (queryData) {
          queryData = Object.assign({}, queryData);
        }
        if (route.route) {
          if (route.route.includes(":")) {
            const routeParams = Array.from(route.route.matchAll(/\/:([^/]+)/g));
            const routeParamData = Object.assign(
              {},
              postData,
              queryData,
              routeArgs
            );
            let routePath = route.route;
            routeParams.forEach((paramMatch) => {
              routePath = routePath.replace(
                `:${paramMatch[1]}`,
                routeParamData[paramMatch[1]]
              );
            });
            routeUrl += routePath;
          } else {
            routeUrl += route.route;
          }
        }
        if (!route.multipart) {
          options.headers["Content-Type"] = "application/json";
        }
        if (route.responseType) {
          options.responseType = route.responseType;
        }
        if (queryData) {
          const queryDataString = new URLSearchParams(queryData).toString();
          if (queryDataString) {
            routeUrl += "?" + queryDataString;
          }
        }
        options.body = route.multipart ? postData : JSON.stringify(postData);
        options.method = route.method || "GET";
        return this.fetch(routeUrl, options).then((data) => {
          if (typeof route.after === "function") {
            return route.after(data, { api: this, type: name, route: route });
          }
          return data;
        });
      };
    });
    Object.entries(methods).forEach((entry) => {
      resource[entry[0]] = entry[1].bind(resource);
    });
    this[name] = resource;
  }
}

export default Api;
