const User = {
  url: "/users",
  routes: {
    me: {
      method: "GET",
      route: "/me",
    },
    changePassword: {
      method: "PUT",
      route: "/:id/password",
      arguments: ["id"],
    },
    lostPassword: {
      method: "POST",
      route: "/lost-password",
    },
    resetPassword: {
      method: "POST",
      route: "/reset-password",
    },
    verifyToken: {
      method: "POST",
      route: "/verify/token",
    },
  },
};

export default User;
