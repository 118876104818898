import AuthApi from "./AuthApi";
import env from "../env";

import Auth from "./Auth";
import Media from "./Media";
import User from "./User";

const api = new AuthApi({
  url: env.apiHost + env.version,
  refreshTokenUrl: "/auth/local/token",
  cookieDomain: env.cookieDomain,
});

export default api;
api.resource("Auth", Auth);
api.resource("Media", Media);
api.resource("User", User);
